<template>
  <div class="animated fadeIn">
    <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
    </loading>
    <div v-if="data">
      <div class="clearfix">
        <b-link class="pull-right mb-3" variant="primary" :to="{
          name: 'deposit.edit',
          params: { id: this.data.id },
          _blan
        }" target='_blank'>
          Засах
        </b-link>
      </div>
      <b-card class="mb-0">
        <h4 class="card-title text-uppercase text-primary">
          Барьцаа хөрөнгө
        </h4>
        <b-row>
          <b-col md="6">
            <span class="text-muted">Төрөл</span>
            <h5 class="mb-0">{{ selected_parent_type.name }}/{{ data.typeName }}</h5>
            <b-row>
              <b-col md=12>
              <hr>
            </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <span class="text-muted">Нэр</span>
            <h5 class="mb-0">{{ data.name }}</h5>
            <b-row>
              <b-col md=12>
              <hr>
            </b-col>
            </b-row>
          </b-col>

          <b-col md="6" v-for="(value, index) in data.values" :key="index">

            <span class="text-muted">{{ value.attributeLabel }}</span>
            <h5 class="mb-0" v-if="value.valueType == 'MONEY'">{{ value.value | currecry }} ₮</h5>
            <h5 class="mb-0" v-else-if="value.valueType == 'NUMBER'">{{ value.value | number }}</h5>
            <h5 class="mb-0" v-else>{{ value.value }}</h5>
            <b-row>
              <b-col md=12>
                <hr>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div v-else>
      <b-button variant="primary" :to="{
        name: 'deposit.new',
        params: { loanAccountId: this.loanId },
      }">
        Барьцаа хөрөнгө бүртгэх
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "new-loan",
  props: ["loanId"],
  data: function () {
    return {
      isLoading: false,
      form: {
        depositSpec: "",
        depositOperatedSince: 0,
        depositOperation: "",
        depositOperatingLocation: "",
        depositOperatingLocationType: "",
        depositSavingBank: "",
        depositSavingBankAccount: "",
        isSingleMother: 0,
        childrenCount: ""
      },
      data: null,
      type_list: [],
      selected_parent_type: {
        id: 0,
        name: ''
      }
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData: function () {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "DepositWebService/get_type_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        }
        )
        .then((response) => {
          this.isLoading = false;
          if (response.data.responseResultType == 'SUCCESS') {
            this.type_list = response.data.datas;
            this.loadData()
          } else {
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    loadData: function () {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "DepositWebService/get_deposit_by_account",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if(response.body.responseResultType == 'SUCCESS') {
              this.$data.data = response.body;
              this.setType(this.data.typeId)
            } else {
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    setType: function (type_id) {
      for (let i in this.type_list) {
        for (let j in this.type_list[i].children) {
          if (this.type_list[i].children[j].id == type_id) {
            this.selected_parent_type = this.type_list[i]
            break
          }
        }
      }
    },
  },
};
</script>
