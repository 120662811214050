<template>
  <div class="animated fadeIn">
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <div v-if="data.id > 0">
      <div class="clearfix">
        <b-link
          class="pull-right mb-3"
          variant="primary"
          :to="{
            name: 'admin.customer-loan-accounts.finance-new',
            params: { id: this.loanId },
          }"
        >
          Засах
        </b-link>
      </div>
      <b-row class="mb-0">
        <b-col md="6">
          <b-card class="mb-0">
            <h4 class="card-title text-uppercase text-primary">
              Салбарын мэдээлэл
            </h4>
            <b-list-group class="mt-4">
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Орлогын төрөл</span>
                    <h5 class="mb-0">{{ getTypeName(data.incomeType) }}</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Салбар</span>
                    <h5 class="mb-0">{{ getTypeName(data.branch) }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Дэд салбар</span>
                    <h5 class="mb-0">{{ getTypeName(data.subBranch) }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="mb-0">
            <h4 class="card-title text-uppercase text-primary">
              Санхүүгийн мэдээлэл
            </h4>
            <b-list-group class="mt-4">
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Өр орлогын харьцаа</span>
                    <h5 class="mb-0">{{ data.debtRatio | currecry }}%</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Сарын орлого</span>
                    <h5 class="mb-0">{{ data.incomeMonthly | currecryZero }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Ипотектэй эсэх</span>
                    <h5 class="mb-0">
                      <b-badge  v-if="data.isIpotek" variant="success">Тийм</b-badge>
                      <b-badge  v-else variant="warning">Үгүй</b-badge>
                    </h5></b-col
                  >
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-button
        variant="primary"
        :to="{
          name: 'admin.customer-loan-accounts.finance-new',
          params: { id: this.loanId },
        }"
      >
      Санхүүгийн мэдээлэл бүртгэх
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "new-loan-extra",
  props: ["loanId"],
  data: function() {
    return {
      isLoading: false,
      data: {
        id: 0,
        incomeType: null, 
        branch: null, 
        subBranch: null
      },
      income_type_list: [],
      branch_list: [],
      sub_branch_list: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData: function() {
      this.isLoading = true;

      this.$http
        .get(
          this.$config.API_URL + "LoanWebService/get_finance",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            console.log(response)
            this.isLoading = false;
            // if(response.body.re)
            this.$data.data = response.body;
          },
          (response) => {
            this.isLoading = false;
          }
        );


      this.$data.types = []
        this.$http
        .get(
          this.$config.API_URL + "LoanWebService/get_loan_finance_list",
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            
            if(response.body.success) {
              this.$data.types = response.body.types
            } 
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getTypeName: function(_id) {
      for(var i in this.$data.types) {
        if(this.$data.types[i].id === _id) {
          return this.$data.types[i].name
        }
      }
      return ''
    }
  },
};
</script>
