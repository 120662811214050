<template>
  <div class="animated fadeIn">
    <b-card no-focus>
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
        <b-form-group>
        <label for="">Хариуцаж буй зээлийн эдийн засагч</label> 
        <select class="form-control" v-model="relatedId">   
            <option :key="index" v-for="(item, index) in employees" :value="item.id">
              @{{ item.firstname }}
            </option>
        </select>
      </b-form-group>
      <b-form-group class="mb-0">
        <b-button variant="success" @click="saveRelatedUser">Хадгалах</b-button>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>

export default {
  name: "Loan.DetailRelated",
  data: function() {
    return {
      noteReload: false,
      isLoading: false,
      relatedId: null,
      id: this.$route.params.id,
      employees: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function() {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "LoanWebService/get_related_user", {
          params: { id: this.$route.params.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            this.$data.relatedId = response.body.logCreatedById;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
      this.$data.noteReload = !this.$data.noteReload;


      this.$http
        .get(this.$config.API_URL + "AdminWebService/get_loan_employee_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.$data.employees = response.body.data
          },
          (response) => {}
        );
    },
    saveRelatedUser: function() {
      this.isLoading = true;
      this.$http
          .post(
            this.$config.API_URL + "LoanWebService/set_related_user",
            { id: this.$route.params.id,
            user_id: this.$data.relatedId},
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              console.log(response)
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
              } else {
                let _ms = response.body.failureMessages.message;
                for (var i in _ms) {
                  this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                }
              }
            },
            (response) => {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            }
          );
    }
  },
};
</script>
