var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.isLoading=$event}}}),(_vm.data)?_c('div',[_c('div',{staticClass:"clearfix"},[_c('b-link',{staticClass:"pull-right mb-3",attrs:{"variant":"primary","to":{
        name: 'deposit.edit',
        params: { id: this.data.id },
        _blan: _vm._blan
      },"target":"_blank"}},[_vm._v(" Засах ")])],1),_c('b-card',{staticClass:"mb-0"},[_c('h4',{staticClass:"card-title text-uppercase text-primary"},[_vm._v(" Барьцаа хөрөнгө ")]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Төрөл")]),_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.selected_parent_type.name)+"/"+_vm._s(_vm.data.typeName))]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('hr')])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Нэр")]),_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.data.name))]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('hr')])],1)],1),_vm._l((_vm.data.values),function(value,index){return _c('b-col',{key:index,attrs:{"md":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(value.attributeLabel))]),(value.valueType == 'MONEY')?_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm._f("currecry")(value.value))+" ₮")]):(value.valueType == 'NUMBER')?_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm._f("number")(value.value)))]):_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(value.value))]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('hr')])],1)],1)})],2)],1)],1):_c('div',[_c('b-button',{attrs:{"variant":"primary","to":{
      name: 'deposit.new',
      params: { loanAccountId: this.loanId },
    }}},[_vm._v(" Барьцаа хөрөнгө бүртгэх ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }