<template>
  <div class="animated fadeIn">
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <div v-if="data.id > 0">
      <div class="clearfix">
        <b-link
          class="pull-right mb-3"
          variant="primary"
          :to="{
            name: 'admin.customer-loan-accounts.deposit-new',
            params: { id: this.loanId },
          }"
        >
          Засах
        </b-link>
      </div>
      <b-row class="mb-0">
        <b-col md="6">
          <b-card class="mb-0">
            <h4 class="card-title text-uppercase text-primary">
              Барьцаа хөрөнгө
            </h4>
            <b-list-group class="mt-4">
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Үйлдвэрлэгдсэн он</span>
                    <h5 class="mb-0">{{ data.factoryYear }}</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">МУ-д орж ирсэн он</span>
                    <h5 class="mb-0">{{ data.incomeYear }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Марк</span>
                    <h5 class="mb-0">{{ data.mark }}</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Улсын дугаар</span>
                    <h5 class="mb-0">{{ data.countryCode }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Арлын дугаар</span>
                    <h5 class="mb-0">{{ data.registrationCode }}</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Өнгө</span>
                    <h5 class="mb-0">{{ data.color }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">GPS serial</span>
                    <h5 class="mb-0">{{ data.gpsSerial }}</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Дата сим дугаар</span>
                    <h5 class="mb-0">{{ data.dataSimNumber }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="mb-0">
            <h4 class="card-title text-uppercase text-primary">Даатгал</h4>
            <b-list-group class="mt-4">
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Даатгалын нэр</span>
                    <h5 class="mb-0">{{ data.insuranceName }}</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Даатгалын төрөл</span>
                    <h5 class="mb-0">{{ data.insuranceType }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Эхлэх огноо</span>
                    <h5 class="mb-0">{{ data.insuranceStartDate }}</h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Дуусах огноо</span>
                    <h5 class="mb-0">{{ data.insuranceEndDate }}</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Үнэлгээ хийсэн дүн</span>
                    <h5 class="mb-0">
                      {{ data.insuranceEvaluationAmount | currecry }}₮
                    </h5></b-col
                  >
                  <b-col sm="6"
                    ><span class="text-muted">Даатгалын хувь </span>
                    <h5 class="mb-0">{{ data.insurancePercent }}%</h5></b-col
                  >
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                class="pl-0 rounded-0 border-right-0 border-left-0"
              >
                <b-row>
                  <b-col sm="6"
                    ><span class="text-muted">Даатгал хийлгэсэн дүн </span>
                    <h5 class="mb-0">
                      {{ data.insuranceAmount | currecry }}₮
                    </h5></b-col
                  >
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-button
        variant="primary"
        :to="{
          name: 'admin.customer-loan-accounts.deposit-new',
          params: { id: this.loanId },
        }"
      >
        Барьцаа хөрөнгө бүртгэх
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "new-loan",
  props: ["loanId"],
  data: function() {
    return {
      isLoading: false,
      form: {
        factory_year: null,
        income_year: null,
        mark: "",
        country_code: "",
        registration_code: "",
        color: "",
        gps_serial: "",
        data_sim_number: "",
        insurance_name: "",
        insurance_type: "",
        insurance_start_date: null,
        insurance_end_date: null,
        insurance_evaluation_amount: 0,
        insurance_percent: 0,
        insurance_amount: 0,
      },
      data: {
        id: 0,
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData: function() {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "LoanWebService/get_deposit_insurance_data",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            // if(response.body.re)
            this.$data.data = response.body;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
